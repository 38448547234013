/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '@pcs/authentication';

import { type Observable, Subject } from 'rxjs';
import { map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { type UserClaimModel } from '@core/user/user-claim.model';
import { UserPictureService } from '@core/user/user-picture.service';
import { UrlHelper } from '@shared/url-helper';

import { type UserFeature, type UserProfile } from './user-profile.model';
import { UserRole } from './user-role.enum';

@Injectable()
export class UserService {
  public userProfileResolved$: Subject<void> = new Subject<void>();

  public profileData: UserProfile;
  private profileUrl: string = UrlHelper.getProfileUrl();
  private userFeatures: UserFeature[];

  constructor(
    private http: HttpClient,
    private userPictureService: UserPictureService,
    private authenticationService: AuthenticationService
  ) {}

  public get userInitials(): string {
    return `${this.profileData.warehouseContactFirstName[0]}${this.profileData.warehouseContactLastName[0]}`;
  }

  public getUserProfile(): Observable<UserProfile> {
    return this.http.get<UserProfile>(this.profileUrl).pipe(
      map((data) => {
        this.profileData = data;
        this.userFeatures = data.userFeatures;
        this.userProfileResolved$.next();
        return data;
      })
    );
  }

  public userHasRole(role: UserRole): boolean {
    return !!this.profileData.roles.find(
      (eachRole) => eachRole === UserRole[role]
    );
  }

  public isFeatureEnable(featureName: string): boolean {
    const feature = this.userFeatures.find((el) => el.name === featureName);
    return !!(feature && feature.isEnabled);
  }

  public listenUserPictureUpdates(): void {
    this.userPictureService.userPictureNeedUpdate$
      .pipe(mergeMap(() => this.getUserProfile()))
      .subscribe((user: UserProfile) => {
        this.userPictureService.userPictureUpdated(user.pictureUrl);
      });
  }

  public deleteProfilePicture(): Observable<void> {
    return this.http.delete<void>(UrlHelper.getUserPictureUrl()).pipe(
      tap(() => {
        this.userPictureService.userPictureUpdated(null);
      })
    );
  }

  public getUserClaims(): Observable<UserClaimModel> {
    return this.authenticationService
      .getCurrentUserAsync()
      .pipe(switchMap(() => this.getSamUserInfo()));
  }

  private getSamUserInfo(): Observable<UserClaimModel> {
    return this.http.get<UserClaimModel>(UrlHelper.getUserInfoUrl());
  }
}
