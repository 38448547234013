/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { type DataRequestDashboardItemModel } from '@pcs/dashboard-data-source';

import { type WindowRef } from '@core/browser/window-ref.model';
import { DashboardItemsStore } from '@core/dashboard/dashboard-items.store';

import {
  BOARD_TYPES,
  MultiplePageNavigationMap,
  PCS_APPS,
} from '../../shared/layout-navigation.const';
import { UrlHelper } from '../../shared/url-helper';
import { WindowRefService } from '../browser/windowRef.service';

@Injectable()
export class LayoutNavigationService {
  private window: WindowRef;

  constructor(
    private windowRefService: WindowRefService,
    private router: Router,
    private dashboardItemsStore: DashboardItemsStore
  ) {
    this.window = this.windowRefService.getWindowReference();
  }

  public onBoardNavigation(basePath: string, boardId: string): void {
    const layoutPath = UrlHelper.getManagerBoardUrl(basePath, boardId);
    this.window.location.href = `${BOARD_TYPES[basePath]}#${layoutPath}`;
  }

  public onMainDashboardNavigation(): void {
    this.resolveNavigation(PCS_APPS.DASHBOARD);
  }

  public onTransactionDashboardNavigation(): void {
    this.resolveNavigation(PCS_APPS.TRANSACTION);
  }

  public onOngoingDashboardNavigation(): void {
    this.resolveNavigation(PCS_APPS.ONGOING);
  }

  public onIlevelDashboardNavigation(): void {
    this.dashboardItemsStore
      .getIlevelDashboardItems()
      .subscribe((dataRequests: DataRequestDashboardItemModel[]) => {
        if (dataRequests.length > 1) {
          this.window.location.href = UrlHelper.getFinancialReportingUrl();
        } else if (dataRequests.length === 1) {
          this.onIlevelCompanyNavigation(dataRequests[0].company.id);
        }
      });
  }

  public onIlevelCompanyNavigation(companyId: number): void {
    this.window.location.href =
      UrlHelper.getFinancialReportingCompanyUrl(companyId);
  }

  public navigateToOngoingEntityDashboard(entityId: number): void {
    this.window.location.href =
      UrlHelper.getOngoingEntityDashboardUrl(entityId);
  }

  public onAdminNavigation(): void {
    this.resolveNavigation(PCS_APPS.ADMIN);
  }

  private resolveNavigation(navigateTo: string): void {
    if (navigateTo === PCS_APPS.DASHBOARD) {
      this.router.navigate([PCS_APPS.DASHBOARD]);
    } else {
      this.window.location.href = MultiplePageNavigationMap[navigateTo];
    }
  }
}
