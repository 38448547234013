/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
import {
  type OnInit,
  Component,
  Inject,
  Renderer2,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import {
  AUTHENTICATION_CORE_SERVICE,
  AuthenticationCoreService,
  ConfigurableSessionTimeoutService,
  Identity,
  RefreshTokenService,
} from '@pcs/authentication';

import { LoadingMaskService } from '@core/loading-mask/layout-loading-mask.service';
import { TimeoutWorkerService } from '@core/timeout-worker/timeout-worker.service';
import { PCSLoadingComponent } from '@shared/loading/loading.component';

@Component({
  selector: 'pcs-app',
  encapsulation: ViewEncapsulation.None,
  styleUrls: [
    './core/styles/app.style.scss',
    './core/cupcake/cupcake-override.scss',
  ],
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  @ViewChild(PCSLoadingComponent, { static: true })
  public loadingMask: PCSLoadingComponent;

  constructor(
    // This stuff should present despite the fact that CupCake requires this
    public viewContainer: ViewContainerRef,
    public renderer: Renderer2,
    @Inject(AUTHENTICATION_CORE_SERVICE)
    private authenticationCoreService: AuthenticationCoreService,
    private loadingMaskService: LoadingMaskService,
    private refreshTokenService: RefreshTokenService,
    private timeoutWorkerService: TimeoutWorkerService,
    private configurableSessionTimeoutService: ConfigurableSessionTimeoutService
  ) {}

  public ngOnInit(): void {
    this.initTokenRefresh();
    this.initSessionTimeout();
    this.loadingMaskService.setLoadingMask(this.loadingMask);
  }

  private initTokenRefresh(): void {
    const timeoutWorker = this.timeoutWorkerService.getTimeoutWorker();
    this.refreshTokenService.setTimeoutWorker(timeoutWorker);

    if (this.authenticationCoreService.IDENTITY_SOURCE === Identity.SAM) {
      this.refreshTokenService.initiateRefreshTokenLogic();
    }
  }

  /**
   * Please read detailed documentation about this feature
   * https://pcsprism.atlassian.net/wiki/spaces/PCS/pages/3055878145/Session+Timeout
   * **/
  private initSessionTimeout(): void {
    this.configurableSessionTimeoutService.setSessionTimeout();
  }
}
