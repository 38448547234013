/* *
 * Copyright (C) 2023 S&P Global.
 * All Rights Reserved
 * Notice: The information, data, processing technology, software (including source code),
 * technical and intellectual concepts and processes and all other materials provided
 * (collectively the "Property") are Copyright © 2023, S&P Global and/or its affiliates
 * (together "S&P Global") and constitute the proprietary and confidential information of
 * S&P Global. S&P Global reserves all rights in and to the Property. Any copying,
 * reproduction, distribution, transmission or disclosure of the Property, in any form, is
 * strictly prohibited without the prior written consent of S&P Global. Unless otherwise
 * agreed in writing, the Property is provided on an "as is" basis and S&P Global makes no
 * warranty, express or implied, as to its accuracy, completeness, timeliness, or to any
 * results to be obtained by recipient nor shall S&P Global in any way be liable to any
 * recipient for any inaccuracies, errors or omissions in the Property. Without limiting the
 * generality of the foregoing, S&P Global shall have no liability whatsoever to any
 * recipient of the Property, whether in contract, in tort (including negligence), under
 * warranty, under statute or otherwise, in respect of any loss or damage suffered by any
 * recipient as a result of or in connection with such Property, or any course of action
 * determined, by it or any third party, whether or not based on the Property. S&P Global,
 * the S&P Global logo, and the IHS Markit logo are registered trademarks of S&P Global,
 * and the trademarks of S&P Global used herein are protected by international laws.
 * Any other names may be trademarks of their respective owners.
 **/
export class UrlHelper {
  public static documentBaseUrl: string = '/documents/api';
  public static prismBaseUrl: string = '/prism/api/v2';
  public static ongoingBaseUrl: string = 'ongoing/api';
  public static platformBaseUrl: string = 'platform/api';
  public static ilevelBaseUrl: string = 'ilevelapi/api';
  public static userTimezone: string = '/TimeZone';
  public static dataRequestDashboardUrl: string = '/DataRequest/dashboard';
  public static linkGenerationUrl: string = '/LinkGeneration/ilevel';
  public static userRegistrationUrl: string = '/user/registration';

  public static readonly appConfigUrl: string = 'app-config/config.json';

  public static notification: string = '/inapp';

  public static ilevelDataRequestDashboardUrl: string = `ilevelapi/api${UrlHelper.dataRequestDashboardUrl}`;
  public static ilevelInvestorlinkGenerationUrl: string = `${UrlHelper.ilevelBaseUrl}${UrlHelper.linkGenerationUrl}`;

  public static readonly transactionReportingWorkspaceUrl: string = `${UrlHelper.prismBaseUrl}/workspace/v2`;
  public static readonly ongoingReportingDashboardUrl: string = `${UrlHelper.ongoingBaseUrl}/Dashboard`;

  public static readonly companyUrl: string = 'platform/api/company';

  private static rootUrl: string = '/';
  private static adminPageUrl: string = '/admin';
  private static transactionsUrl: string = '/tvdr';
  private static ongoingUrl: string = '/ongoing';
  private static financialReportingUrl: string = '/financial-reporting';

  public static apiWorkspaceUrl(workspaceId: number): string {
    return `${this.prismBaseUrl}/workspace/${workspaceId}`;
  }

  public static getConfigurationDataUrl(): string {
    return `${this.platformBaseUrl}/configuration`;
  }

  // Company Search
  public static getCompaniesByName(name: string): string {
    return `${this.companyUrl}?name=${name}`;
  }

  public static getCompaniesByDomain(domain: string): string {
    return `${this.companyUrl}?domain${domain}`;
  }

  public static getCompanyById(companyId: string): string {
    return `${this.companyUrl}/${companyId}`;
  }

  public static getCompaniesMatches(
    workspaceId: number,
    domain: string
  ): string {
    return `${this.apiWorkspaceUrl(
      workspaceId
    )}/participant/companyMatch/${domain}`;
  }

  // Entity Address
  public static getCountriesUrl(): string {
    return `${this.companyUrl}/countries`;
  }

  public static getProvincesUrl(countryId: number): string {
    return `${this.companyUrl}/countries/${countryId}/provinces`;
  }

  public static getDocumentBaseUrl(): string {
    return this.documentBaseUrl;
  }

  public static getPrismBaseUrl(): string {
    return this.prismBaseUrl;
  }

  public static getOngoingBaseUrl(): string {
    return this.ongoingBaseUrl;
  }

  public static getPlatformBaseUrl(): string {
    return this.platformBaseUrl;
  }

  public static getProfileUrl(): string {
    return `${UrlHelper.platformBaseUrl}/Profile`;
  }

  public static getUserInfoUrl(): string {
    return `${UrlHelper.platformBaseUrl}/oauth2/userinfo`;
  }

  public static getUserRegistrationUrl(): string {
    return this.userRegistrationUrl;
  }

  public static getTransactionsUrl(): string {
    return this.transactionsUrl;
  }

  public static getOngoingUrl(): string {
    return this.ongoingUrl;
  }

  public static getAdminPageUrl(): string {
    return this.adminPageUrl;
  }

  public static getRootUrl(): string {
    return this.rootUrl;
  }

  //Notifications

  public static getNotificationUrl(): string {
    return `${this.platformBaseUrl}${this.notification}`;
  }

  public static getManagerBoardUrl(basePath: string, id: string): string {
    return `/${basePath}/${id}/home`;
  }

  public static getRecipientBoardUrl(
    basePath: string,
    id: number,
    rootFolderId: number
  ): string {
    return `/${basePath}/${id}/documents/${rootFolderId}`;
  }

  public static getUserTimezoneUrl(): string {
    return `${this.prismBaseUrl}${this.userTimezone}`;
  }

  public static getSessionUrl(): string {
    return `${this.prismBaseUrl}/Session`;
  }

  public static getDataRequestGenerateLink(dataRequestId: number): string {
    return `${UrlHelper.ilevelBaseUrl}/DataRequest/${dataRequestId}/generateLink`;
  }

  public static getFinancialReportingUrl(): string {
    return this.financialReportingUrl;
  }

  public static getFinancialReportingCompanyUrl(companyId: number): string {
    return `${this.getFinancialReportingUrl()}#/company/${companyId}`;
  }

  public static getOngoingEntityDashboardUrl(entityId: number): string {
    return `${this.getOngoingUrl()}#/dashboard/ongoing/entity/${entityId}`;
  }

  public static getUserPictureUrl(): string {
    return `${this.getProfileUrl()}/picture`;
  }

  public static getUserRegistrationStartUrl(): string {
    return `${UrlHelper.getPlatformBaseUrl()}${this.getUserRegistrationUrl()}/start`;
  }

  public static getUserRegistrationCompleteUrl(): string {
    return `${UrlHelper.getPlatformBaseUrl()}${this.getUserRegistrationUrl()}/complete`;
  }

  /**
   * Get query params from location
   *
   * returns { queryParamName: string, value: string }
   */
  public static getQueryParams(): { [key: string]: string } {
    const dirtyParams = location.search.substring(1).split('&');
    const result = {};

    dirtyParams.forEach((param: string) => {
      const temp = param.split('=');
      const queryParamName = temp[0];
      const value = temp[1];

      result[queryParamName] = value;
    });

    return result;
  }
}
